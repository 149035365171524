export default {
    home: {
        home: 'front page',
        kyjsfw: 'Scientific research and technical services',
        wzbyfw: 'Text compilation service',
        zxtg: 'Online Submit',
        kfzl: 'Customer service direct contact',
        swhz: 'Business Cooperation',
        address: 'Room 1112A, Guozhong Commercial Building, No. 33 Dengshikou Street, Dongcheng District, Beijing│Beijing ICP No. 09027056-1——Beijing Public Security No. 11010102002695',
    },
    homeTwo: {
        aboutus: 'about us',
        hqbj: 'get a quote',
        phone: 'Telephone',
        name: 'Name',
        yzm: 'verification code',
        pleasephone: 'please enter phone number',
        pleasename: 'Please type in your name',
        pleaseyzm: 'please enter verification code',
        getnow: 'get it now',
        sunmit: 'submit',
        fwys: 'Service advantages',
        gxxl: 'College Trust',
        hzdw: 'The cooperation unit',
        ts: 'hint',
        namerules: 'name cannot be empty',
        phonerules: 'Phone cannot be empty',
        yzmfs: 'Verification code sent',
        yzmrules: 'verification code must be filled',
    },
    kyjsfw: {
        zxzx: 'online consultation',
        bljc: 'Pathological detection',
        bljc1: 'In Situ Cardinal Apoptosis Detection TEM & SEM',
        bljc2: 'In situ hybridization detection Tissue chip preparation',
        bljc3: 'tissue in paraffin bag special stain',
        bljc4: 'Immunohistochemistry (IHC) HE staining',
        cpxq: 'Product Details',
        cpxq0: 'SCI course full assistance service',
        fwjs: '【Service Introduction】',
        fwjs1: 'It is a personalized customized service for professional assistance in the whole process of academic papers from evaluation to journal acceptance. Through our full assistance with professional senior scholars, authors can publish journal papers more effectively.',
        fwjs2: 'You only need to provide the original manuscript in Chinese or English. Yujia Medical combines years of experience in editing and polishing SCI academic papers and a strong professional team, as well as a good cooperative relationship with international journal publishers. Every step will be supervised by rich review experience and rich submission experience. Experts and scholars provide assistance throughout the process, greatly improving the hit rate of papers. For articles that pass the evaluation, if they are not published, a full refund will be made.',
        shrq: '[suitable for the crowd]',
        shrq1: 'In the process of journal submission, many unsolvable problems are encountered, but the authors who must publish SCI papers',
        shrq2: 'Authors who have the first draft of the article in Chinese or English and hope to get full assistance in the publication of the paper',
        shrq3: 'Authors who have no submission experience, or who have little knowledge of journal submissions',
        shrq4: 'Authors whose submissions have been rejected many times, or who want to submit to higher-level journals after full assistance',
        shrq5: 'Authors looking for expert advice on strategic submissions',
        shrq6: 'Compared with authors who lack experience in writing English papers and have experience in submitting manuscripts to international journals',
        fwlc: '【Service Process】',
        fwys: '【Service advantagps】',
        fwys1: '1. Good cooperative relationship with international journal publishers to ensure faster collection',
        fwys2: '2. A peer-editing team composed of professors, reviewers, and journal editors from global institutions of higher learning',
        fwys3: '3. The reviewers of well-known journals make a preliminary evaluation of the paper',
        fwys4: '4. Provide all-round guidance and revision opinions and unlimited in-depth editing and polishing services to improve the quality of the article to ensure that the article reaches the level of SCI publication',
        fwys5: '5. Reduce the authors time investment',
        fwys6: '6. The author will supplement the experimental data by himself, and the experts will assist in the modification',
        fwys7: '7. Unlimited number of revisions/length of revisions, unlimited free retouching until the paper is published',
        fwzq: '【Service cycle】',
        fwzq0: 'Time: 15-18 months (not including experiment time)',
        fwjg: '【service fee】',
        fwjg0: 'Negotiable pricing',
        cjwt: '【common problem】',
        cjwtq1: 'Q: Does choosing your SCI full publishing service guarantee that my article will be accepted?',
        cjwta1: 'Pujia: Before our formal service, our editors will check your manuscript. If it is confirmed that we can provide you with this service, within the agreed service period, if your manuscript is not accepted by the magazine, we promise to pay the full amount Refund.',
        cjwtq2: 'Q: If I intend to choose this service, what materials do I need to submit?',
        cjwta2: 'Yujia: full text of the article, attached materials ideal journals, submission history, review comments, etc.). After receiving the complete manuscript submitted by you, we will ask the editor to check and review your manuscript. If the editor confirms that we can provide you with the full SCI publication service, we will quote you.',
        cjwtq3: 'Q: Can SCIs full publishing service select for my target journal or guarantee the impact factor of the published journal?',
        cjwta3: 'Yujia: The whole process of SCI publishing service is within the agreement period, which guarantees that your manuscript can be accepted by SCI magazine smoothly, but does not guarantee the impact factor of the accepted journal. If you have a target journal, Yujia editor will use it as a reference, but we There is no guarantee that the final journal you submit to will be the one you expected',
    },
    zxtg: {
        userNameLabel: 'username',
        phoneNumberLabel: 'Phone number',
        EMail: 'E-mail',
        companyName:'company name',
        liwenbianji:'understand the channel',
        qxyz: 'please verify first',
        remark: 'Message and Remarks',
        validCode: 'please enter verification code',
        validCodeTitle: 'get verification code',
        userName: 'please enter user name',
        phoneNumber: 'Please enter phone number',
        hqbj: 'Online Submit',
        pleaseemail: 'Please enter email',
        pleasename: 'Please enter the unit name',
        ljlwbj: 'Where did you learn about Liwen editing?',
        iszc: 'Are you already a registered user? Log in now for faster service',
        fwxm: 'What service items do you need?',
        xjrs: 'quick polish',
        bzrs: 'standard polish',
        yyjz: 'English correction',
        other: 'other',
        wj: 'document',
        djsc: 'Click to upload',
        lyybz: 'Messages and remarks: target journal website; expected return date; alternate email address; other special requirements (provide more information above so that we can serve you more accurately',
        bygb: '(Please be sure not to close the page until you see the message "Order Successful", thank you.)',
        yhm: 'Promo Code',
        yzm: 'verification code',
        yzmts: 'This question is used to test whether you are a user who submits an order normally to prevent spam orders from being automatically submitted.',
        srtpxszf: 'enter confirmation code',
        submit: 'submit',
        dwrules: 'Unit name cannot be empty',
        emailrules: 'Email cannot be empty',
        pleasefwxm: 'Please select the service item you need',

    },
    aboutus: {
        aboutus: 'about us',
        js: 'In recent years, our company has used high-performance computers to carry out virtual drug screening has been widely used. Computer-aided drug design can improve the success rate of drug research and development, reduce research and development costs, and shorten the research and development cycle. It is one of the core technologies of innovative drug research. With the accumulation of medical big data and the development of artificial intelligence technology, the precise drug design using AI technology combined with big data is also constantly promoting the development of innovative drugs. In the treatment plan for the new coronavirus, through a series of computer-aided drug biocomputation methods, a large class of drug molecules can effectively prevent the infection of the new coronavirus, which provides a new idea for the treatment of the new coronavirus. Safety, speed, professionalism, high-end fast English editing, peer estimation, academic translation provide you with a more efficient service experience every second, make every choice you want to be satisfactory, and have a very high experience effect to help you Complete each selection. Our company has many international journals, scientific research institutions and universities trusting from 4600 Canadian universities, the companys hospitals and scientific research institutes choose to trust Shangji SAGESCIs services such as paper polishing, translation language rewriting, submission assistance, etc., to add to their academic success Help!',
        fwyzl: '【Our company has professional service and quality】',
        fwyzl1: '1. Free professional editing service',
        fwyzl2: '2 Match subject areas',
        fwyzl3: '3. Two rounds of proofreading and one-on-one online answering of original questions, allowing you to save time and match subject experts in the same field to edit your manuscript with high-quality, worry-free and labor-saving manuscripts for paper polishing and dissertation Translation Polishing Services for Translators',
        fwyzl4: '4. High customer satisfaction',
        fwyzl5: '5. Information confidentiality',
        fwyzl6: '6. Quality commitment, customer satisfaction 98.8%, witness the quality is that we protect the customers privacy, we still set it as the first guarantee of service, and we will not take safe confidentiality measures and The manuscript was rejected by the journal due to formatting errors.',
        bottom1: 'Safety, speed, professionalism, high-end fast English editing, peer estimation, academic translation provide you with a more efficient service experience every second, make every choice you want to be satisfactory, and have a very high experience effect to help you Complete each selection.',
        bottom2: 'Our company has many international journals, scientific research institutions and universities trusting from 4600 Canadian universities, the companys hospitals and scientific research institutes choose to trust Shangji Sagescis services such as paper polishing, translation language rewriting, submission assistance, etc., to add to their academic success Help!',
        lxfs: 'contact details',
    }
}