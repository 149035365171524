<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import $ from 'jquery'
function initScreen() {
  var viewport = document.querySelector("meta[name=viewport]");
  if (!viewport) {
    $("head").append('<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes">')
  }
  if (!document.querySelector("meta[http-equiv]")) {
    $("head").append('<meta http-equiv="X-UA-Compatible" content="ie=edge">')
  }
  var viewport = document.querySelector("meta[name=viewport]");
  var winWidth = $(window).width();
  var size = winWidth / 1200;
  if (winWidth < 480) {
    viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, user-scalable=yes');
    $("html").css({ "transform": "scale(" + size + ")", "transform-origin": "0 0", 'width': '1200px', "height": "100vh" });
  }
}
$(function () {
  initScreen()
})
export default {

}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  border: none;
}

html,
body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: SimHei !important;
}

div {
  box-sizing: border-box !important;
}
</style>
