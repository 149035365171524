<template>
  <div>
    <div class="header">
      <h2 style="margin:5px 0">{{ $t('zxtg.hqbj') }}</h2>
      <p style="color:#646464;font-size:12px;">Online Submit</p>
    </div>

    <div class="content">
      <div class="content-div1">
        <el-form label-width="140px" :model="formLabelAlign" :rules="rules" ref="ruleForm">
          <div class="div">
            <el-form-item prop="userName" :label="$t('zxtg.userNameLabel')">
              <el-input v-model="formLabelAlign.userName" :placeholder="$t('zxtg.userName')"
                class="itemclass"></el-input>
            </el-form-item>
            <el-form-item prop="tel" :label="$t('zxtg.phoneNumberLabel')">
              <el-input v-model="formLabelAlign.tel" :placeholder="$t('zxtg.phoneNumber')" class="itemclass"></el-input>
            </el-form-item>
            <el-form-item prop="email" :label="$t('zxtg.EMail')">
              <el-input v-model="formLabelAlign.email" :placeholder="$t('zxtg.pleaseemail')"
                class="itemclass"></el-input>
            </el-form-item>
            <el-form-item prop="address" :label="$t('zxtg.companyName')">
              <el-input v-model="formLabelAlign.address" :placeholder="$t('zxtg.pleasename')"
                class="itemclass"></el-input>
            </el-form-item>
            <el-form-item prop="phone" :label="$t('zxtg.liwenbianji')">
              <el-select v-model="formLabelAlign.knownWay" :placeholder="$t('zxtg.ljlwbj')" class="itemclass">
                <el-option v-for="item in options1" :key="item.value" :label="item.text" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('zxtg.remark')">
              <el-input type="textarea" :rows="3" :placeholder="$t('zxtg.lyybz')" v-model="formLabelAlign.remark">
              </el-input>
            </el-form-item>
            <!-- <div style="margin: 50px 80px;color:red">{{ $t('zxtg.iszc') }}</div> -->
            <div style="margin: 20px 80px;">{{ $t('zxtg.fwxm') }}<span style="color:red">*</span></div>
            <el-form-item prop="wantService">
              <el-checkbox-group v-model="checkList" @change="checkboxchange">
                <el-checkbox v-for="item in options2" :key="item.value" :label="item.text"
                  :value="item.value"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item prop="phone">
              {{ $t('zxtg.wj') }}<span style="color:red">*</span>:
              <input style="display: none" id="fileImg" ref="filesImg" type="file" @change="uploadImg" />
              <el-button size="small" type="primary" @click="goFile">{{ $t('zxtg.djsc') }}</el-button>
            </el-form-item>
            <div style="padding:0 10%;">
              <p v-for="item in fileList">{{ item.name }}</p>
            </div>
            <!-- <div class="whitediv">{{ $t('zxtg.lyybz') }}</div> -->
            <!-- <div style="margin: 20px 80px;">{{ $t('zxtg.bygb') }}</div> -->
            <div class="yanzhengma">
              <div>{{ $t('zxtg.yzm') }}</div>
              <div>{{ $t('zxtg.yzmts') }}</div>
              <div>
                <el-input v-model="formLabelAlign.validCode" :placeholder="$t('zxtg.validCode')"
                  style="width:300px"></el-input>&nbsp;&nbsp;
                <el-button type="success" style="background-color: #39844d;color:#fff;" :disabled="displayStatus"
                  @click="yzclick">{{
                      countDown ? countDown : $t('zxtg.validCodeTitle')
                  }}</el-button>
              </div>
            </div>
            <div style="margin: 20px 80px;">
              <el-button type="success"
                style="background-color: #39844d;color:#fff;padding-left:40px;padding-right:40px" @click="tosubmit">{{
    $t('zxtg.submit')
                }}</el-button>
            </div>
          </div>
        </el-form>
        <div style="height:50px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDictInfoservice, getDictInfoackownleadge, sendSms, uploadOnline } from "@/api/index";
import SlideVerify from "@/components/SlideVerify";
export default {
  comments: { SlideVerify },
  data() {
    return {
      displayStatus: false,
      isShowSlide: false,
      validCodeStatus: false,
      countDown: undefined,
      timer: undefined,
      formLabelAlign: {},
      options: [
        {
          label: "111",
          value: 1
        }
      ],
      options1: [],
      options2: [],
      checkList: [],
      fileList: [],
      rules: {
        userName: [
          { required: true, message: this.$t('zxtg.userName'), trigger: 'blur' },
        ],
        tel: [
          { required: true, message: this.$t('zxtg.phoneNumber'), trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        address: [
          { required: true, message: this.$t('zxtg.dwrules'), trigger: 'blur' },
        ],
        email: [
          { required: true, message: this.$t('zxtg.emailrules'), trigger: 'blur' },
        ],
        wantService: [
          { required: true, message: this.$t('zxtg.pleasefwxm'), trigger: 'blur' },
        ],
        validCode: [
          { required: true, message: this.$t('zxtg.validCode'), trigger: 'blur' },
        ],
      }
    }
  },
  created() {
    this.getDictInfo()
  },
  methods: {
    getDictInfo() {
      getDictInfoservice().then(res => {
        console.log(res, "2222222222");
        this.options2 = res.data
      })
      getDictInfoackownleadge().then(res => {
        this.options1 = res.data
      })
    },
    tosubmit() {
      this.$refs.ruleForm.validate((valid) => {
        console.log(this.formLabelAlign);
        console.log(valid);
        if (valid) {
          let formData = new FormData()
          formData.append('file', this.fileList[0]);
          for (const key in this.formLabelAlign) {
            formData.append(key, this.formLabelAlign[key]);
          }
          console.log(this.formLabelAlign);
          uploadOnline(formData).then(res => {
            console.log(res);
            if (res.code == 0) {
              this.$message.success(res.msg);
              setTimeout(() => {
                location.reload();
                this.$router.go(0);
              }, 2000)
            } else {
              this.$message.error(res.msg);
            }
          })
        } else {
          this.$message({
            message: this.$t('zxtg.qxyz'),
            type: 'warning'
          });
        }
      });
    },
    yzclick() {
      if (this.formLabelAlign.tel && this.formLabelAlign.tel?.length == 11) {
        // this.isShowSlide = true
        this.sendSmsCode()
      } else {
        this.$message.error(this.$t('zxtg.phoneNumber'));
      }
    },
    hideSlide() {
      let TIME_COUNT = 60;
      this.countDown = TIME_COUNT
      if (!this.timer) {
        this.timer = setInterval(() => {
          console.log(this.countDown);
          if (this.countDown > 0 && this.countDown <= TIME_COUNT) {
            this.countDown--
          } else {
            this.countDown = undefined
            this.displayStatus = false;
            clearInterval(this.timer);
          }
        }, 1000)
      }

    },
    sendSmsCode() {
      setTimeout(() => {
        sendSms(this.formLabelAlign.tel).then(res => {
          if (res.code == 0) {
            this.$message({
              message: this.$t('homeTwo.yzmfs'),
              type: 'success'
            });
            this.displayStatus = true
            this.hideSlide()
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }

        }).finally(e => {
          this.isShowSlide = false;
        })
      }, 500);

    },
    checkboxchange() {
      console.log(this.checkList);
      let data = ''
      if (this.checkList.length) {
        this.checkList.map(item => {
          data += item + ','
        })
      }
      console.log(data, "3333");
      this.$set(this.formLabelAlign, 'wantService', data)
    },
    goFile() {
      this.$refs.filesImg.click();
    },
    uploadImg(e) {
      this.fileList = e.target.files
      console.log(e);
      console.log(this.fileList);
    },
    beforeAvatarUpload(file) {
      console.log(file);
      this.fileList.push(file)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #39844b;
}


.content {
  margin-bottom: 80px;

  // width: 800%;
  .content-div1 {
    margin: auto;
    width: 80%;

    .whitediv {
      background: white;
      padding: 10px;
      border-radius: 5px;
      margin: 20px 0px 20px 80px;
    }

    .text-style {
      font-size: 10px;
      color: red;
    }

    .itemclass {
      width: 100%;
    }

    .yanzhengma {
      margin: 50px 0px 20px 80px;

      div {
        margin-bottom: 10px;
      }
    }

    .div {
      // width: 100%;
      background: #F0F0F0;
      padding-top: 20px;
      padding-left: 20%;
      padding-right: 20%;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .item {
        border-bottom: 1px solid #39844b;
        padding: 5px;
        margin-bottom: 20px;

        .float {
          float: right;
        }
      }
    }
  }
}
</style>