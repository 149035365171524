/**
 * 公共基础请求模块
 */

import request from '@/utils/request'
// 关于我们
export const aboutUs = (data) => {
    return request({
        method: 'POST',
        url: '/web/aboutUs',
        data,
    })
}
//获取服务优势(service)、高校信赖(school)、合作单位(partner)
export const getServiceInfo = (data) => {
    return request({
        method: 'POST',
        url: '/web/getServiceInfo',
        data,
    })
}
//获取首页头图
export const getIndexPic = (data) => {
    return request({
        method: 'POST',
        url: '/web/getIndexPic',
        data,
    })
}
//获取文章编译
export const getCompile = (data) => {
    return request({
        method: 'POST',
        url: '/web/getCompile',
        data,
    })
}
//获取科研技术服务
export const getTechnology = (data) => {
    return request({
        method: 'POST',
        url: '/web/getTechnology',
        data,
    })
}
//获取科研技术服务
export const sendSms = (data) => {
    return request({
        method: 'POST',
        url: '/web/sendSms',
        data
    })
}
//获取报价
export const acquirePrice = (data) => {
    return request({
        method: 'POST',
        url: '/web/acquirePrice',
        data
    })
}
//获取报价
export const getDictInfoservice = (data) => {
    return request({
        method: 'get',
        url: '/web/getDictInfo/service',
        data
    })
}
export const getDictInfoackownleadge = (data) => {
    return request({
        method: 'get',
        url: '/web/getDictInfo/ackownleadge',
        data
    })
}
//在线投稿
export const uploadOnline = (data) => {
    return request({
        method: 'POST',
        url: '/web/uploadOnline',
        data,
        headers:{
        "Content-Type": 'multipart/form-data',
        }
    })
}
// 获取公司基本信息
export const getCompanyData = (data) => {
    return request({
        method: 'POST',
        url: '/web/getCompanyData',
        data,
    })
}
// 获取公司基本信息
export const getArticleDetail = (data) => {
    return request({
        method: 'POST',
        url: '/web/getArticleDetail',
        data,
    })
}