<template>
  <div>
    <div class="header">
      <h2 style="margin:5px 0">{{ $t('homeTwo.aboutus') }}</h2>
      <p style="color:#646464;font-size:12px;">about us</p>
    </div>
    <div v-html="htmlData"></div>
  </div>
</template>

<script>
import { aboutUs } from "@/api/index";
export default {
  data() {
    return {
      htmlData: '',
    };
  },
  methods: {
    getAboutUs() {
      aboutUs().then(res => {
        console.log(res);
        this.htmlData = res.data
      })
    }
  },
  created() {
    this.getAboutUs()
  }
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.content {
  width: 100%;
  margin-bottom: 80px;

  .img-style {
    text-align: center;
    display: flex;
    justify-content: center;

    div:nth-child(2) {
      margin: 0 5px 0 10px;
    }

    div:nth-child(3) {
      margin: 0 10px 0 5px;
    }
  }

  .img-style2 {
    text-align: center;
    display: flex;
    justify-content: center;

    div {
      font-weight: 600;
      line-height: 30px;
      padding: 10px 0px 5px 10px;
    }
  }

  .img-style-two {
    text-align: center;
    display: flex;
    justify-content: space-around;
    padding: 0 25%;
  }

  .img-style-three {
    text-align: center;
    display: flex;
    justify-content: center;

    div:nth-child(2) {
      margin: 0 10px;
    }
  }

  .form-style {
    text-align: center;
    padding: 0 25%;
  }

  h2 {
    color: #39844b;
  }

  .flex-box {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .padding20 {
    padding: 0 20%;
  }


}

.jiance {
  display: inline-block;
  width: 550px;
  padding: 10px;
}

.text-content {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 30px;

  h2 {
    color: black;
  }

  .xian {
    width: 100px;
    height: 5px;
    background: #39844d;
  }

  .title {
    text-align: center;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 80px;
  }

  .lvsetitle {
    color: #39844d;
    font-size: 20px;
  }

  .duanluo {
    margin: 20px 10px;
  }
}

.jiance2 {
  text-align: center;
  width: 600px;
}
</style>