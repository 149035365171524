import axios from 'axios';
import qs from 'qs';
import { Message } from 'element-ui';
const instance = axios.create({
    // baseURL: 'http://175.27.188.78:8080', //接口前段
    baseURL: 'https://www.boenbiology.com/api', //接口前段
    // headers: {
    //     // 'Content-Type': 'application/x-www-form-urlencoded',
    //     'Content-Type': 'application/json;charset=utf-8',
    //     // "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
    // },
    // transformRequest: [function (data, config) {
    //     if (config['Content-Type'] == 'application/x-www-form-urlencoded') data = qs.stringify(data);
    //     if (config['Content-Type'] == 'application/json') data = JSON.stringify(data)
    //     return data;
    // }],
    // withCredentials: true,
    //qsc上传400M 时间15分钟
    timeout: 900000
});
const language = localStorage.getItem('language') || 'zh'
instance.interceptors.request.use(function (config) {
    config.headers = {
        "Content-Type": 'application/json;charset=utf-8',
        "language": language,
        ...config.headers
    };
    console.log(config.url);

    if (config.data && typeof (config.data) != 'object') config.url += '/' + config.data
    if (config['Content-Type'] != 'multipart/form-data') config.url += '?' + qs.stringify(config.data)
    // if (typeof (config.data) == 'object') config.url = config.url + '?' + qs.stringify(config.data)

    return config;
}, (error) => {
    console.log('请求报错');
    return error;
},)
instance.interceptors.response.use(function (config) {
    if (config.status == 200) {
        return config.data;
    } else {
        Message.error(config)
    }
    return config;
}, (error) => {
    console.log('响应报错');
    return error;
})
export default instance;