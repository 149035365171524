<template>
	<div>
		<div class="header">
			<!-- <img src="../assets/home/bannar-ch.jpg" width="100%" height="500px" alt=""> -->
			<el-image style="width: 100%; height: 600px" :src="homeImg" fit="cover"></el-image>
			<div class="fix-btn" @click="abooutus">
				{{
						$t('homeTwo.aboutus')
				}}
			</div>
		</div>
		<div class="content">
			<div>
				<div style=" text-align: center;margin:40px 0;">
					<h2 style="margin:5px 0">
						{{ $t('home.kyjsfw') }}
					</h2>
					<p style="color:#646464;font-size:14px;">Scientific research and technical services</p>
				</div>
				<div class="img-style">
					<el-row>
						<el-col :span="6" v-for="item in getTechnologyList" :key="item.id">
							<el-image class="elImage" :src="item.pic" fit="scale-down"
								@click="imageClick(item.id)"></el-image>
							<div class="elImage-box">
								<p style="color:#646464;font-size:24px;">{{ item.text }}</p>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div>
				<div style=" text-align: center;margin:40px 0;">
					<h2 style="margin:5px 0">
						{{ $t('home.wzbyfw') }}
					</h2>
					<p style="color:#646464;font-size:14px;">Text compilation service</p>
				</div>
				<div class="img-style">
					<el-row>
						<el-col :span="6" v-for="item in compilationList" :key="item.id" class="img-box">
							<el-image class="elImage" :src="item.pic" fit="scale-down"
								@click="imageClick(item.id)"></el-image>
							<div class="elImage-box">
								<p style="color:#646464;font-size:28px;">{{ item.text }}</p>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div>
				<div style=" text-align: center;margin:40px 0;">
					<h2 style="margin:5px 0">
						{{ $t('homeTwo.hqbj') }}
					</h2>
					<p style="color:#646464;font-size:14px;">
						Get Quote
					</p>
				</div>
				<div class="form-style">
					<div class="form-div">
						<el-form label-position="top" :model="formLabelAlign" :rules="rules" ref="ruleForm">
							<el-form-item :label="$t('homeTwo.name')" prop="userName">
								<el-input v-model="formLabelAlign.userName"
									:placeholder="$t('homeTwo.pleasename')"></el-input>
							</el-form-item>
							<el-form-item :label="$t('homeTwo.phone')" prop="telephone">
								<el-input v-model="formLabelAlign.telephone"
									:placeholder="$t('homeTwo.pleasephone')"></el-input>
							</el-form-item>
							<el-form-item :label="$t('homeTwo.yzm')" prop="validCode" v-if="validCodeStatus">
								<el-input v-model="formLabelAlign.validCode"
									:placeholder="$t('homeTwo.pleaseyzm')"></el-input>
							</el-form-item>
						</el-form>
					</div>

				</div>
				<div class="button-box">
					<el-button type="success" v-if="!validCodeStatus" round
						style="background-color: #39844d;color:#fff;" @click="formClick">{{
								$t('homeTwo.getnow')
						}}</el-button>
					<el-button type="success" v-if="validCodeStatus" round style="background-color: #39844d;color:#fff;"
						@click="acquirePrice">{{ $t('homeTwo.sunmit') }}</el-button>
				</div>
			</div>
			<div>
				<div style=" text-align: center;margin:40px 0;">
					<h2 style="margin:5px 0">
						{{ $t('homeTwo.fwys') }}
					</h2>
					<p style="color:#646464;font-size:14px;">Service advantages</p>
				</div>
				<div class="img-style-three">
					<el-row>
						<el-col :span="8" v-for="item in serviceList" :key="item.pic">
							<div class="img-box">
								<el-image class="elImage" :src="item.pic" fit="scale-down"></el-image>
								<div v-html="item.text" class="img-text"></div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div>
				<div style=" text-align: center;margin:3rem 0;">
					<h2 style="margin:5px 0">
						{{ $t('homeTwo.gxxl') }}
					</h2>
					<p style="color:#646464;font-size:14px;">College Trust</p>
				</div>
				<div class="flex-box">
					<div style="width:500px;">
						<div style="width:500px;text-align:left;" v-html="schoolList.remark" class="left-box"></div>
					</div>

					<div style="width: 600px">
						<el-row>
							<el-col :span="8" v-for="item, index in schoolList.pic" :key="item">
								<el-image style="width:200px;height: 100px;object-fit: contain;" :src="item"
									fit="scale-down"></el-image>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
			<div>
				<div>
					<div style="text-align: center;margin:40px 0;">
						<h2 style="margin:5px 0">
							{{ $t('homeTwo.hzdw') }}
						</h2>
						<p style="color:#646464;font-size:14px;">The cooperation unit</p>
					</div>
					<div>
						<div style class="padding20">
							<el-image v-for="item in partnerList" :key="item"
								style="width: 10%; height: 100%;margin: 0 15px;" :src="item" fit="cover"></el-image>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getServiceInfo, getIndexPic, getCompile, getTechnology, sendSms, acquirePrice } from "@/api/index";
import SlideVerify from "@/components/SlideVerify";
export default {
	comments: { SlideVerify },
	data() {
		return {
			isShowSlide: false,
			validCodeStatus: false,
			formLabelAlign: {
			},
			schoolList: {},
			partnerList: [],
			serviceList: [],
			compilationList: [],
			getTechnologyList: [],
			homeImg: '',
			rules: {
				userName: [
					{ required: true, message: this.$t('homeTwo.namerules'), trigger: 'blur' },
				],
				telephone: [
					{ required: true, message: this.$t('homeTwo.phonerules'), trigger: 'blur' },
					{ min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
				],
			}
		}
	},
	created() {
		this.getIndexPic();
		this.getServiceInfo();
		this.getCompile();
		this.getTechnology();
	},
	mounted() {
	},
	methods: {
		imageClick(imageID) {
			console.log(imageID);
			this.$emit('detailPageClick', imageID)
		},
		acquirePrice() {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					acquirePrice(this.formLabelAlign).then(res => {
						if (res.code == 0) {
							this.$message({
								message: res.msg,
								type: 'success'
							});
							delete this.rules.validCode
							this.formLabelAlign = {}
							this.validCodeStatus = false;
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							})
						}

					})
				}
			});

		},
		hideSlide() {
			setTimeout(() => {
				console.log('发送请求');
				this.isShowSlide = false;
			}, 500);
		},
		sendSmsCode() {
			setTimeout(() => {
				sendSms(this.formLabelAlign.telephone).then(res => {
					if (res.code == 0) {
						this.$message({
							message: res.msg,
							type: 'success'
						});
						// this.validCodeStatus = true;
						this.$set(this.rules, 'validCode', [
							{ required: true, message: this.$t('homeTwo.yzmrules'), trigger: 'blur' },
						])
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
					}

				}).finally(e => {
					this.isShowSlide = false;
				})
			}, 500);

		},
		formClick() {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.validCodeStatus = true;
					this.sendSmsCode()
				}
			});
		},
		//获取科研技术服务
		getTechnology() {
			getTechnology().then(res => {
				console.log(res);
				this.getTechnologyList = res.data;
			})
		},
		//获取文章编译
		getCompile() {
			getCompile().then(res => {
				this.compilationList = res.data;
			})
		},
		//获取首页头图
		getIndexPic() {
			getIndexPic().then(res => {
				this.homeImg = res.data
			})
		},
		getServiceInfo() {
			getServiceInfo().then(res => {
				this.schoolList = res.data.school;
				this.partnerList = res.data.partner.pic;
				this.serviceList = res.data.service;
			}).catch(e => {
				console.log(e);
			})
		},
		// 向
		abooutus() {
			this.$emit('handleClick', 5)
			
		}
	}
}
</script>

<style lang="less" scoped>
.header {
	width: 100%;
	position: relative;

	.fix-btn {
		position: absolute;
		bottom: 173px;
		left: 360px;
		width: 205px;
		height: 71px;
		background-color: #c2e5b7;
		color: #387943;
		font-size: 31px;
		text-align: center;
		align-items: center;
		line-height: 71px;
		border-radius: 35px;
		font-weight: 400;
	}
}

.content {
	width: 100%;

	.img-style {
		padding: 0 15%;
		text-align: center;
		display: flex;
		justify-content: space-around;

		.elImage {
			width: 300px;
			height: 150px;
			margin: 10px 0;
		}

		.elImage-box {
			width: 320px;
			font-size: 20px;
			font-weight: 600;
		}
	}


	.img-style-two {
		text-align: center;
		display: flex;
		justify-content: space-around;
	}

	.img-style-three {
		padding: 0 10%;
		text-align: center;
		color: #fff !important;

		.img-box {
			width: 100%;
			height: 100%;
			margin: 0 auto;
			margin-bottom: 10px;
			position: relative;

			.elImage {
				width: 95%;
				height: 100%;
			}

			.img-text {
				width: 100%;
				height: 100%;

				position: absolute;
				top: 35%;
				// left: 0;
				z-index: 2;
				text-align: center;
			}
		}
	}

	.hzdw-img {
		height: 100px;
		object-fit: contain;
	}

	.padding20 {
		padding: 0 20%;
		text-align: center;
	}

	.padding10 {
		padding: 0 10%;
	}

	.hzdw-img {
		width: 150px;
		height: 100px;
		margin: 0 15px;
	}

	.form-style {
		padding: 0 25%;
		text-align: center;
		display: flex;
		justify-content: space-around;

		.form-div {
			width: 300px;
		}
	}

	.button-box {
		padding: 0 25%;
		text-align: center;
	}

	h2 {
		color: #39844b;
		font-size: 38px;
	}

	.flex-box {
		text-align: center;
		display: flex;
		justify-content: center;

		.left-box {
			padding: 40px 35px 0 80px;
		}

	}

}
</style>