<template>
  <div id="home">
    <div>
      <el-row class="header">
        <el-col :span="4">
          <div class="logo">
            <img src="../assets/home/logo2.jpg" width="58px" height="58px" alt="">
          </div>
        </el-col>
        <el-col :span="15">
          <el-row class="bannar">
            <el-col :span="6">
              <span class="cursor" :class="{ span1: spanKey == '1' }" @click="handleClick('1')">{{ $t('home.home')
              }}</span>
            </el-col>
            <el-col :span="6">
              <span class="cursor" :class="{ span1: spanKey == '2' }" @click="handleClick('2')">{{ $t('home.kyjsfw')
              }}</span>
            </el-col>
            <el-col :span="6">
              <span class="cursor" :class="{ span1: spanKey == '3' }" @click="handleClick('3')">{{ $t('home.wzbyfw')
              }}</span>
            </el-col>
            <el-col :span="6">
              <span class="cursor" :class="{ span1: spanKey == '4' }" @click="handleClick('4')">{{ $t('home.zxtg')
              }}</span>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="5">
          <div class="srarch">
            <el-button round style=" background-color: #39844b;color: #fff;" @click="changeLang">中/EN</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="content">
      <component :is="childrenName" :ref="childrenName" :particularsID="particularsID" @handleClick="handleClick"
        @detailPageClick="detailPageClick" />
    </div>
    <div class="footer">
      <div style="color:#fff">
        <p style="margin-bottom:10px">
          {{ $t('home.kfzl') }}&nbsp;:&nbsp;&nbsp;{{ companyData.telephone }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {{ $t('home.swhz') }}&nbsp;:&nbsp;&nbsp;{{ companyData.telephone }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          EMAIL&nbsp;: &nbsp;&nbsp;{{ companyData.email }}</p>
        <p>{{ companyData.address }}|{{ companyData.policeNo }}|<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff;text-decoration: none;">{{ companyData.webNo }}</a></p>
      </div>
    </div>
    <div class="contact"><a href='javascript:__egeelTalk();'><img src="../assets/home/contact.jpg"></a></div>

  </div>
</template>

<script>
import HomeTwo from "./homeTwo.vue";
import Zaixiantougao from "./zaixiantougao.vue";
import Kyjsfw from "./kyjsfw.vue";
import Wzbyfw from "./wzbyfw.vue";
import Aboutus from "./aboutus.vue";
import detailPage from "./detailPage.vue";
import { getCompanyData } from "@/api/index";
export default {
  data() {
    return {
      spanKey: "1",
      particularsID: '',
      childrenName: 'HomeTwo',
      companyData: {}
    };
  },
  created() {
    this.getCompanyData();
  },
  mounted() {
    (function () { document.getElementsByTagName("head")[0].appendChild(document.createElement("script")).src = (location.protocol.toLocaleLowerCase() == "https:" ? "https:" : "http:") + "//v.fmkefu.com/cs/js?u=3749_3823&c=auto"; })();
  },
  methods: {
    getCompanyData() {
      getCompanyData().then(res => {
        console.log(res);
        this.companyData = res.data
      })
    },
    changeLang() {
      if (localStorage.getItem('language') == 'zh') {
        localStorage.setItem('language', 'en')
      } else {
        localStorage.setItem('language', 'zh')
      }
      location.reload();
      this.$router.go(0);
    },
    detailPageClick(e) {
      this.particularsID = e;
      this.childrenName = 'detailPage'
    },
    handleClick(e) {
      this.spanKey = e;
      if (e == 1) {
        this.childrenName = 'HomeTwo'
      } else if (e == 2) {
        this.childrenName = 'Kyjsfw'
      } else if (e == 3) {
        this.childrenName = 'Wzbyfw'
      } else if (e == 4) {
        this.childrenName = 'Zaixiantougao'
      } else if (e == 5) {
        this.childrenName = 'Aboutus'
      }
    },
  },
  components: { HomeTwo, Zaixiantougao, Kyjsfw, Wzbyfw, Aboutus, detailPage }
}
</script>

<style lang="less" scoped>
#home {
  .contact {
    position: fixed;
    top: 40%;
    right: 0;
  }

  .header {
    width: 100%;
    min-height: 90px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #39844b;
    z-index: 10;

    .logo {
      text-align: center;
      padding-top: 16px;
      height: 100%;
    }

    .bannar {
      width: 100%;
      color: #fff;
      text-align: center;
      padding-top: 29px;
      span {
        display: inline-block;
        font-size: 22px;
        letter-spacing: 3px;
      }
    }

    .srarch {
      line-height: 90px;
      text-align: center;
      font-size: 22px;
    }
  }

  .content {
    margin-top: 90px;
    margin-bottom: 40px;
    min-height: calc(~'(100vh - 20vh)');
  }

  .span1 {
    padding: 0 10px;
    background-color: #fff;
    color: #39844b;
    border-radius: 10px;
  }

  .footer {
    width: 100%;
    height: 100px;
    background-color: #39844b;
    text-align: center;
    align-items: center;
    padding-top: 25px;
    font-size: 14px;
  }

  .cursor {
    cursor: pointer
  }
}
</style>