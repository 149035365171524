<template>
  <div class="box-no">
    <div class="header">
      <h2 style="margin:5px 0;font-size: 38px;">{{ $t('home.kyjsfw') }}</h2>
      <p style="color:#646464;font-size:14px;">Scientific research and technical services</p>
    </div>
    <div class="content">
      <div>
        <!-- <div style="text-align: center; margin: 40px 0">
          <h2>科研技术服务</h2>
          <p>Scentifc research and tedhnical services</p>
        </div> -->
        <div class="img-style">
          <el-row>
            <el-col :span="6" v-for="item in getTechnologyList" :key="item.id">
              <el-image class="elImage" @click="imageClick(item.id)" :src="item.pic" fit="scale-down"></el-image>
              <div class="elImage-box">
                <p>{{ item.text }}</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTechnology } from "@/api/index";

export default {
  data() {
    return {
      getTechnologyList: [],
    };
  },
  created() {
    this.getTechnology();
  },
  methods: {
    imageClick(imageID) {
      console.log(imageID);
      this.$emit('detailPageClick', imageID)
    },
    //获取科研技术服务
    getTechnology() {
      getTechnology().then(res => {
        this.getTechnologyList = res.data;
      })
    },
  },
};
</script>

<style lang="less" scoped>
.box-no {
  min-height: 500px;

  .header {
    width: 100%;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    color: #39844b;
  }

  .content {
    width: 100%;

    .img-style {
      padding: 0 15%;
      text-align: center;
      display: flex;
      justify-content: space-around;

      .elImage {
        width: 300px;
        height: 150px;
        margin: 10px 0;
      }

      .elImage-box {
        width: 320px;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .img-style2 {
      text-align: center;
      display: flex;
      justify-content: center;

      div {
        font-weight: 600;
        padding: 10px 0px 5px 10px;
      }
    }

    .img-style-two {
      text-align: center;
      display: flex;
      justify-content: space-around;
      padding: 0 25%;
    }

    .img-style-three {
      text-align: center;
      display: flex;
      justify-content: center;

      div:nth-child(2) {
        margin: 0 10px;
      }
    }

    .form-style {
      text-align: center;
      padding: 0 25%;
    }

    h2 {
      color: #39844b;
      font-size: 38px;
    }

    .flex-box {
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .padding20 {
      padding: 0 20%;
    }


  }

  .jiance {
    display: inline-block;
    width: 500px;
    padding: 10px;
  }

  .text-content {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 30px;

    h2 {
      color: black;
    }

    .xian {
      width: 100px;
      height: 5px;
      background: #39844d;
    }

    .title {
      text-align: center;
      font-size: 24px;
      margin-top: 30px;
      margin-bottom: 80px;
    }

    .lvsetitle {
      color: #39844d;
      font-size: 20px;
    }

    .duanluo {
      margin: 20px 10px;
    }
  }
}
</style>