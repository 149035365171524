import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from "vue-i18n";
import elementZH from './lang/zh'
import elementEN from './lang/en'
import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.use(SlideVerify);
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueI18n)
if (!localStorage.getItem('language')) {
  localStorage.setItem('language', 'zh')
}
let language = localStorage.getItem('language') || 'zh';
console.log(language);
const i18n = new VueI18n({
  locale: language,
  messages: {
    zh: {
      ...elementZH
    },
    en: {
      ...elementEN
    },
  }
})
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')