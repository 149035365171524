<template>
    <div class="box-no1">
        <div class="header">
            <h2 style="margin:5px 0;font-size: 38px;">{{ $t('home.wzbyfw') }}</h2>
            <p style="color:#646464;font-size:14px;">Text compilation service</p>
        </div>
        <div class="content">
            <div class="img-style">
                <el-row>
                    <el-col :span="6" v-for="item in compilationList" :key="item.id" class="img-box">
                        <el-image class="elImage" @click="imageClick(item.id)" :src="item.pic"
                            fit="scale-down"></el-image>
                        <div class="elImage-box">
                            <p>{{ item.text }}</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompile } from "@/api/index";
export default {
    data() {
        return {
            compilationList: [],
            formLabelAlign: {},
            options: [],
            checkList: [],
        }
    }, created() {
        this.getCompile();
    },
    methods: {
        imageClick(imageID) {
            console.log(imageID);
            this.$emit('detailPageClick', imageID)
        },
        //获取文章编译
        getCompile() {
            getCompile().then(res => {
                this.compilationList = res.data;
            })
        },
    }
}
</script>

<style lang="less" scoped>
.box-no1 {
    min-height: calc(~"(100vh - 30vh)");
}

.header {
    width: 100%;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    color: #39844b;
}

.content {

    .img-style {
        padding: 0 15%;
        text-align: center;
        display: flex;
        justify-content: space-around;

        .elImage {
            width: 300px;
            height: 150px;
            margin: 10px 0;
        }

        .elImage-box {
            width: 320px;
            font-size: 20px;
            font-weight: 600;
        }
    }
}
</style>