export default {
    home: {
        home: '首页',
        kyjsfw: '科研技术服务',
        wzbyfw: '文字编译服务',
        zxtg: '在线投稿',
        kfzl: '客服直联',
        swhz: '商务合作',
        address: '北京市东城区灯市口大街33号国中商业大厦1112A室│京ICP备09027056号-1――京公网安备11010102002695号',
    },
    homeTwo: {
        aboutus: '关于我们',
        hqbj: '获取报价',
        phone: '电话',
        name: '姓名',
        yzm: '验证码',
        pleasephone: '请输入电话',
        pleasename: '请输入姓名',
        pleaseyzm: '请输入验证码',
        getnow: '立即获取',
        sunmit: '提交',
        fwys: '服务优势',
        gxxl: '高校信赖',
        hzdw: '合作单位',
        ts: '提示',
        namerules: '姓名不能为空',
        phonerules: '电话不能为空',
        yzmfs: '验证码已发送',
        yzmrules: '验证码不能为空'
    },
    kyjsfw: {
        zxzx:'在线咨询',
        bljc: '病理检测',
        bljc1: '原位卡端凋亡检测 透射电镜&扫描电镜',
        bljc2: '原位杂交检测 组织芯片制备',
        bljc3: '组织石蜡包里 特殊染色',
        bljc4: '免疫组化（IHC） HE染色',
        cpxq: '产品详情',
        cpxq0: 'SCI课程全程协助服务',
        fwjs: '【服务介绍】',
        fwjs1: '是针对学术论文从评估到期刊接收全过程进行专业协助的个性化定制服务，通过我们同专业资深学者的全程协助使作者更有效地发表期刊论文。',
        fwjs2: '您只需要提供中文或英文原稿，誉嘉医学结合多年SCI学术论文编辑润色经验和强大的专业团队，以及与国际期刊出版社的良好合作关系，每个步骤都将由丰富审稿经验、丰富投稿经验的专家学者，全程进行协助，大大提高论文命中率。对于通过评估的文章，如未发表，全额退款。',
        shrq: '【适合人群】',
        shrq1: '在期刊投稿过程中，遇到很多无法解决的问题，但是必须发表SCI论文的作者',
        shrq2: '有中文或英文文章的初稿，希望得到全程协助论文发表的作者',
        shrq3: '没有投稿经验，或者对期刊投稿了解很少的作者',
        shrq4: '投稿多次被拒，或者想通过全程协助后，投更高水平期刊的作者',
        shrq5: '希望专家能够提供战略性投稿建议的作者',
        shrq6: '比较缺乏英文论文撰写经验额投稿国际期刊经验的作者',
        fwlc: '【服务流程】',
        fwys: '【服务优势】',
        fwys1: '1、与国际期刊出版社的良好合作关系，保障更快收录',
        fwys2: '2、由全球高等学府的教授、审稿人、期刊编辑组成的同行编辑团队',
        fwys3: '3、知名期刊审稿人对论文进行初步评估',
        fwys4: '4、对文章质量提升提供全方位的指导修改意见和无限次深度编辑润色服务，保证文章达到SCI发表水平',
        fwys5: '5、降低作者在时间上的投入',
        fwys6: '6、由作者自行补充实验数据，专家协助修改',
        fwys7: '7、不限修改次数/修改篇幅，无限次免费润色直至论文发表',
        fwzq: '【服务周期】',
        fwzq0: '时间：15-18个月（不包含实验时间）',
        fwjg: '【服务价格】',
        fwjg0: '面议定价',
        cjwt: '【常见问题】',
        cjwtq1: 'Q：选择你们的SCI全程发表服务能保证我的文章一定被接收吗?',
        cjwta1: '普嘉:在我们正式服务之前，我们编辑会对您的文稿进行把关，如确定可以为您提供这项服务，在协定服务期限内，如若您的文稿没有被杂志社接受，我们承诺将全额退款。',
        cjwtq2: 'Q:如果我有意选择这项服务，需要提交什么材料?',
        cjwta2: '誉嘉:文章全文，附件材料理想期刊、投稿历史、审稿意见等)。收到您提交的完整文稿后，我们会请编辑对您的文稿进行把关审核，如编辑确定可以为您提供SCI全程发表服务，我们将为您报价。',
        cjwtq3: 'Q:SCI全程发表服务能投我的目标杂志或者保证发表杂志的影响因子吗?',
        cjwta3: '誉嘉:SCI全程发表服务是在协议期限内，保证您的文稿能被SCI杂志顺利接受，但对接受杂志的影响因子不做保证，如您有目标杂志，誉嘉编辑会作为参考，但我们无法保证最终投稿的杂志就是您期待的那个杂志',
    },
    zxtg: {
        userNameLabel:'用户名',
        phoneNumberLabel:'手机号',
        EMail:'电子邮箱',
        companyName:'单位名称',
        liwenbianji:'了解渠道',
        qxyz:'请先验证',
        remark:'留言与备注',
        validCode:'请输入验证码',
        validCodeTitle:'获取验证码',
        userName:'请输入用户名',
        phoneNumber:'请输入手机号',
        hqbj: '在线投稿',
        pleaseemail: '请输入电子邮件',
        pleasename: '请输入单位名称',
        ljlwbj: '您是从何处了解理文编辑的',
        iszc: '您已经是注册用户？马上登录，获取更快速的服务',
        fwxm: '您需要什么服务项目？',
        xjrs: '迅捷润色',
        bzrs: '标准润色',
        yyjz: '英语矫正',
        other: '其他',
        wj: '文件',
        djsc: '点击上传',
        lyybz: '留言与备注：目标期刊网址；希望返稿日期；备用电子邮箱；其他特别要求（提供以上更多信息，以便我们更准确的为您服务',
        bygb: '(请务必不要关闭页面，知道您看到“订单成功”的信息提示，谢谢。)',
        yhm: '优惠码',
        yzm: '验证码',
        yzmts: '此问题用于测试您是否是正常提交订单的用户，用以阻止自动提交的垃圾订单。',
        srtpxszf: '输入验证码',
        submit: '提交',
        dwrules: '单位名称不能为空',
        emailrules: '电子邮件不能为空',
        pleasefwxm: '请选择需要的服务项目',

    },
    aboutus: {
        aboutus: '关于我们',
        js: '近年来，我司利用高性能计算机来进行药物虚拟筛选已经被广泛应用，计算机辅助药物设计可以提高药物研发的成功率，降低研发成本，缩短研发周期，是目前创新药物研究的核心技术之一。随着医药大数据的积累和人工智能技术的发展，运用AI技术并结合大数据的精准药物设计也不断推动着创新药物的发展。在新型冠状病毒的治疗方案中，通过一系列计算机辅助药物生物计算的方法发现一大类药物分子可以有效阻止新冠病毒的侵染，为治疗新冠提供了新思路。安全、速度、敬业、高端快捷英文润色同行预估评价、学术翻译每分每秒为您提供更高效的服务体验让你的每一份选择都称心如意，有着一个极高的体验效果，助您完成每一份选择。我司拥有众多国际期刊和科研机构及高校信赖来自4600加高校，公司医院、科研院所的科研人员选择信赖尚辑SAGESCI的论文润色与翻译语言改写、投稿协助等服务、为他们的学术成功添加助力!',
        fwyzl: '【我司拥有专业的服务与质量】',
        fwyzl1: '1.免费专业编辑服务',
        fwyzl2: '2匹配学科领域',
        fwyzl3: '3.双轮审校一对一原问在线解答，让您省时，匹配相同领域的学科专家为您的 两位专家编轿为您的稿件做高质量省心、省力稿件作论文润色和论文翻译的翻译润色服务',
        fwyzl4: '4.客户满意度高',
        fwyzl5: '5.信息保密',
        fwyzl6: '6.质量承诺，客户满意度98.8%，见证质量是我们保护客户除私、尚设视作服务第一 保证润色翻译的稿件，不会因为语言翻译润色服务的优势准则，采取稳妥的保密措施 和格式错误而被期刊拒稿。',
        bottom1: '安全、速度、敬业、高端快捷英文润色同行预估评价、学术翻译每分每秒为您提供更高效的服务体验让你的每一份选择都称心如意，有着一个极高的体验效果，助您完成每一份选择。',
        bottom2: '我司拥有众多国际期刊和科研机构及高校信赖来自4600加高校，公司医院、科研院所的科研人员选择信赖尚辑Sagesci的论文润色与翻译语言改写、投稿协助等服务、为他们的学术成功添加助力!',
        lxfs: '联系方式',
    }
}