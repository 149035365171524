<template>
    <div>
        <div class="header">
            <h2>{{ getArticleDetailData.title }}</h2>
        </div>
        <div class="content" v-html="getArticleDetailData.remark">
        </div>
    </div>
</template>

<script>
import { getArticleDetail } from '@/api';

export default {
    props: {
        particularsID: [String, Number]
    },
    data() {
        return {
            getArticleDetailData: {}
        }
    },
    created() {
        this.getArticleDetail();
    },
    methods: {
        getArticleDetail() {
            //this.particularsID
            getArticleDetail(this.particularsID + '').then(res => {
                this.getArticleDetailData = res.data;
                console.log(this.getArticleDetailData);
            })
        }
    }
}
</script>

<style lang="less" scoped>
.header {
    width: 100%;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #39844b;
}

.content {
    margin-bottom: 80px;
    padding: 0 25%;
    ;
}
</style>